import PropTypes from "prop-types"
import React from "react"

const Logo = ({ firstColor, secondColor, classes }) => (
  <svg viewBox="0 0 142 25" className={`${classes} fill-current`}>
    <path
      fill={secondColor}
      d="M139 25c-1.67 0-3-1.268-3-3.03 0-1.795 1.33-2.97 3-2.97s3 1.175 3 2.97c0 1.762-1.33 3.03-3 3.03z"
    />
    <path
      fill={firstColor}
      d="M9.846 24.807c-5.608 0-9.708-3.763-9.708-9.065s4.1-9.066 9.708-9.066c5.608 0 9.674 3.764 9.674 9.066 0 5.302-4.066 9.065-9.674 9.065zm0-4.189c2.558 0 4.492-1.833 4.492-4.876 0-3.044-1.934-4.877-4.492-4.877S5.32 12.698 5.32 15.742c0 3.043 1.968 4.876 4.526 4.876zM33.36 6.676c4.951 0 8.788 3.535 8.788 9.066 0 5.53-3.837 9.065-8.789 9.065-2.36 0-4.263-.752-5.542-2.29v2.028h-4.886V.262h5.116v8.542c1.311-1.44 3.115-2.128 5.312-2.128zm-.886 13.942c2.558 0 4.493-1.833 4.493-4.876 0-3.044-1.935-4.877-4.493-4.877-2.558 0-4.493 1.833-4.493 4.877 0 3.043 1.935 4.876 4.493 4.876zM57.66 6.938h4.92l-7.445 17.607h-5.28L42.443 6.938h5.28l4.887 11.978zM64.678 24.545V6.938h5.116v17.607zM82.945 24.807c-5.608 0-9.708-3.763-9.708-9.065s4.1-9.066 9.708-9.066c5.607 0 9.674 3.764 9.674 9.066 0 5.302-4.067 9.065-9.674 9.065zm0-4.189c2.558 0 4.492-1.833 4.492-4.876 0-3.044-1.934-4.877-4.492-4.877s-4.526 1.833-4.526 4.877c0 3.043 1.968 4.876 4.526 4.876zM108.688 6.938h5.116v17.607h-4.853v-2.094c-1.345 1.538-3.313 2.356-5.477 2.356-4.427 0-7.543-2.487-7.543-7.92V6.938h5.116v9.197c0 2.945 1.312 4.254 3.575 4.254 2.361 0 4.066-1.505 4.066-4.745V6.938zM124.495 24.807c-2.984 0-6.001-.818-7.64-1.898l1.704-3.665c1.575 1.014 3.969 1.701 6.133 1.701 2.361 0 3.247-.621 3.247-1.603 0-2.88-10.659.065-10.659-6.971 0-3.338 3.018-5.695 8.166-5.695 2.427 0 5.116.557 6.789 1.539l-1.706 3.632c-1.738-.982-3.476-1.309-5.083-1.309-2.295 0-3.28.72-3.28 1.637 0 3.01 10.66.098 10.66 7.036 0 3.273-3.05 5.596-8.33 5.596z"
    />
  </svg>
)

Logo.propTypes = {
  firstColor: PropTypes.string,
  secondColor: PropTypes.string,
  classes: PropTypes.string,
}

Logo.defaultProps = {
  firstColor: "#ffffff",
  secondColor: "#101821",
  classes: "h-6 w-auto",
}

export default Logo
