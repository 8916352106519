/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./header"
import "./layout.css"
import Logo from "./logo"
import { Helmet } from "react-helmet"

const Layout = ({ children, showBanner = true }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div className="bg-brand-teal h-screen">
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=UA-131237398-1"
        ></script>
        <script>
          {`window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', 'UA-131237398-1');`}
        </script>
        <script
          id="hs-script-loader"
          async
          defer
          src="https://js.hs-scripts.com/5507326.js"
        ></script>
      </Helmet>
      <Header
        siteTitle={data.site.siteMetadata.title}
        showBanner={showBanner}
      />
      <div
        id="globalContent"
        className="flex flex-col gradient bg-teal min-h-screen overflow-hidden"
      >
        <main className="flex flex-col flex-grow h-full">{children}</main>
        <footer className="bg-brand-grey-dark flex justify-center pb-20">
          <div>
            <div className="flex items-center">
              <a
                href="/"
                className="outline-none h-12 select-none flex items-baseline"
                title="obvious."
              >
                <div className="text-white mr-1 h-full flex items-end">©</div>
                <Logo secondColor="#00b1a9" />
              </a>
            </div>
            <ul className="flex items-center justify-center max-w-3xl pt-4">
              <li class="facebook">
                <a
                  href="https://www.facebook.com/justaddobvious/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w-8 h-8 rounded-full hover:bg-brand-teal flex items-center justify-center mr-1"
                >
                  <svg
                    viewBox="0 0 512 512"
                    id="obvs-facebook"
                    className="text-white fill-current w-auto h-6"
                  >
                    <title>facebook</title>
                    <g stroke="none" fill-rule="evenodd">
                      <path
                        d="M281.310214,422 L281.310214,270.570301 L332.300177,270.570301 L339.950174,211.53795 L281.310214,211.53795 L281.310214,173.854402 C281.310214,156.768511 286.052268,145.124682 310.668852,145.124682 L342.014085,145.111842 L342.014085,92.3112177 C336.593367,91.6092923 317.985961,90 296.328865,90 C251.105394,90 220.144595,117.50563 220.144595,168.007877 L220.144595,211.53795 L169,211.53795 L169,270.570301 L220.144595,270.570301 L220.144595,422 L281.310214,422 Z"
                        fill-rule="nonzero"
                        fill="#fff"
                      ></path>
                    </g>
                  </svg>
                </a>
              </li>
              <li class="instagram">
                <a
                  href="https://www.instagram.com/justaddobvious/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w-8 h-8 rounded-full hover:bg-brand-teal flex items-center justify-center mr-1"
                >
                  <svg
                    viewBox="0 0 512 512"
                    id="obvs-instagram"
                    className="text-white fill-current w-auto h-6"
                  >
                    <title>instagram</title>
                    <g stroke="none" fill-rule="evenodd">
                      <g
                        transform="translate(90.000000, 90.000000)"
                        fill-rule="nonzero"
                        fill="#fff"
                      >
                        <path d="M228.25,0 L103.75,0 C46.45925,0 0,46.45925 0,103.75 L0,228.25 C0,285.54075 46.45925,332 103.75,332 L228.25,332 C285.54075,332 332,285.54075 332,228.25 L332,103.75 C332,46.45925 285.54075,0 228.25,0 Z M300.875,228.25 C300.875,268.2975 268.2975,300.875 228.25,300.875 L103.75,300.875 C63.7025,300.875 31.125,268.2975 31.125,228.25 L31.125,103.75 C31.125,63.7025 63.7025,31.125 103.75,31.125 L228.25,31.125 C268.2975,31.125 300.875,63.7025 300.875,103.75 L300.875,228.25 Z"></path>
                        <path d="M167,84 C121.16325,84 84,121.16325 84,167 C84,212.83675 121.16325,250 167,250 C212.83675,250 250,212.83675 250,167 C250,121.16325 212.83675,84 167,84 Z M167,218.875 C138.4065,218.875 115.125,195.5935 115.125,167 C115.125,138.38575 138.4065,115.125 167,115.125 C195.5935,115.125 218.875,138.38575 218.875,167 C218.875,195.5935 195.5935,218.875 167,218.875 Z"></path>
                        <circle cx="255" cy="77" r="11"></circle>
                      </g>
                    </g>
                  </svg>
                </a>
              </li>
              <li class="twitter">
                <a
                  href="https://twitter.com/justaddobvious"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w-8 h-8 rounded-full hover:bg-brand-teal flex items-center justify-center mr-1"
                >
                  <svg
                    viewBox="0 0 134 134"
                    id="obvs-twitter"
                    className="text-white fill-current w-6 h-6"
                  >
                    <title>twitter</title>
                    <g stroke="none" fill-rule="evenodd" fill="#fff">
                      <path
                        d="M51.78736,101 C82.73088,101 99.65568,74.8363291 99.65568,52.1473116 C99.65568,51.4041776 99.65568,50.6643911 99.60648,49.9279521 C102.899047,47.4974048 105.741246,44.4879418 108,41.0404718 C104.929523,42.429 101.67232,43.3395965 98.33712,43.7418641 C101.84916,41.5960884 104.477685,38.2211554 105.73352,34.2451478 C102.431077,36.2450997 98.8181192,37.6545661 95.05056,38.4127233 C89.8356315,32.7535048 81.5491595,31.3683912 74.8377216,35.0340746 C68.1262837,38.6997581 64.658982,46.5046009 66.38008,54.0720954 C52.853018,53.3800086 40.2498806,46.8594224 31.7072,36.1331097 C27.2418826,43.9783438 29.5226803,54.0147184 36.91584,59.0531014 C34.2385111,58.9721183 31.6195563,58.2350286 29.28,56.9040383 C29.28,56.9743348 29.28,57.0479787 29.28,57.1216226 C29.2821915,65.2947335 34.9273449,72.3342278 42.7772,73.9526023 C40.3003723,74.6419763 37.7016643,74.7427488 35.18072,74.2471779 C37.3847092,81.2414168 43.7007646,86.0328266 50.89848,86.1707955 C44.9411309,90.9490529 37.5818752,93.542978 30.00488,93.535186 C28.6663237,93.5325635 27.3290693,93.4498511 26,93.2874747 C33.6936859,98.326313 42.645716,100.999052 51.78736,100.98661"
                        fill-rule="nonzero"
                      ></path>
                    </g>
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </footer>
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
