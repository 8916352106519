import { Link } from "gatsby"
import React from "react"
import Logo from "./logo"
import AntBanner from "./antBanner"

const Header = ({ showBanner }) => {
  return (
    <header
      className={`absolute top-0 left-0 right-0 z-50 h-auto ${!showBanner &&
        "bg-brand-grey-dark"}`}
    >
      <div className="max-w-6xl p-0 mx-auto w-full mt-8">
        <nav>
          <ul className="m-0 flex flex-wrap lg:flex-no-wrap items-baseline justify-center lg:justify-between">
            <li>
              <Link
                to="/"
                className="outline-none h-12 select-none"
                aria-label="obvıous. homepage"
              >
                <Logo secondColor={!showBanner && "#00b1a9"} />
              </Link>
            </li>
            <li className="justify-center lg:justify-end flex flex-wrap text-white uppercase font-medium  text-base lg:text-sm h-12 mt-2 lg:mt-0 w-full lg:w-auto">
              <div class="dropdown relative">
                <button class="bg-transparent text-white uppercase font-medium mr-4">
                  Services
                </button>
                <ul class="dropdown-menu bg-gray-200 absolute hidden text-gray-700 py-2 w-64 mt-4 rounded">
                  <li class="">
                    <Link
                      to="/platform"
                      className="bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap rounded-t"
                    >
                      Platform
                    </Link>
                  </li>
                  <li class="">
                    <Link
                      to="/accelerate"
                      className="bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap rounded-b"
                    >
                      Consulting
                    </Link>
                  </li>
                </ul>
              </div>
              <Link to="/news" className="mr-4">
                News
              </Link>
              <Link to="/contact" className="mr-4">
                Contact
              </Link>
              <a
                href="https://portal.obvious.xyz"
                className="mr-4 hidden lg:inline"
              >
                Sign in
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  )
}

export default Header
